<template>
	<div>
		<h1>Page not found</h1>
		Click <nuxt-link to="/"> here </nuxt-link> to return
	</div>
</template>

<script>
export default {
	name: 'IndexPage',
};
</script>
